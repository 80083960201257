.form-intro {
  text-align: center;
  margin-bottom: 80px;
}

form {
  width: 100%;

  fieldset {
    border: none;
    padding: 0;
    margin: 0;

    div {
      margin-bottom: 16px;

      span {

        &.small {
          @include font-size(12);
          color: $text;
          margin: 24px 0;
        }
      }
    }
  }

  label {
    @include font-size(12);
    text-transform: uppercase;
    margin-bottom: 8px;
    display: block;
    width: 100%;

    &.radio-container {
      @include font-size(14);
      font-weight: 400;
      margin: 8px 0;
      color: $text;
      position: relative;
      padding-left: 32px;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      input:checked ~ .radiobtn {
        background-color: white;
      }

      input:checked ~ .radiobtn:after {
        display: block;
      }

      .radiobtn {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: white;
        border: 1px solid $border;
        border-radius: 50%;

        &:after {
          top: 2px;
          left: 2px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: $accent;
          content: "";
          position: absolute;
          display: none;
        }
      }
    }
  }

  .field-validation-error {
    @include font-size(14);
    color: red;
    display: block;
    width: 100%;
  }

  .badge-error {
    @include font-size(18);
    color: red;
    display: block;
    font-family: $bold;
  }

  input {
    font-family: roboto;
    background: white;
    border-radius: 28px;
    margin-bottom: 8px;
    color: $text;
    border: 1px solid $border;
    padding: 16px 24px;
    width: 100%;

    &:focus {
      border-color: $text;
      outline: 0;
    }
  }

  textarea {
    background: white;
    border-radius: 28px;
    margin-bottom: 8px;
    color: $text;
    border: 1px solid $border;
    padding: 16px 24px;
    width: 100%;
    outline: 0;

    &:focus-within {
      border-color: $text;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $text-light;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: $text-light;
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: $text-light;
  }


  :-moz-placeholder { /* Firefox 18- */
    color: $text-light;
  }

  .buttons {
    display: flex;
    flex-direction: column;

    @media(min-width: $min-tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  input[type=submit] {
    background: $accent;
    color: $accent-text;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $button-padding;
    border: 2px solid $accent;
    transition: $duration $easing;
    text-decoration: none;
    align-self: flex-start;
    font-family: $bold;
    white-space: nowrap;
    width: auto;

    &:hover {
      background: $accent-hover;
      color: $accent-text;
      border-color: $accent-hover;
      text-decoration: none;
    }

    &:focus-within {
      outline: 2px solid black;
    }
  }
  /*.radioGroup {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div {
      display: flex;
      padding-right: 16px;
      width: 100%;

      @media (min-width: $min-tablet) {
        width: 20%;
      }
      
     
    }
  }*/

  .radiobuttonlist, .dataconsent {
    display: flex;
    flex-wrap: wrap;



    label {
      display: flex;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      user-select: none;
      width: 100%;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark:after {
          display: block;
        }

        &:focus-within ~ .checkmark {
          outline: 1px solid black;
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $border;

        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 3px;
          top: 3px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: $accent;
        }
      }
    }
  }

  .checkboxlist {
    flex-direction: column;
  }

  .checkbox,
  .checkboxlist {
    /*margin-top: 10px;*/
    display: flex;
    margin-top: 0;

    label {
      user-select: none;
      display: flex;
      position: relative;
      padding-left: 32px;
      order: 2;
      color: $text;
      align-items: center;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      order: 1;
      margin-top: 1px;

      &:checked ~ .checkmark:after {
        display: block;
      }

      &:focus-within ~ .checkmark {
        outline: 1px solid black;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: #FFF;
      border: 1px solid $border;
      margin-top: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 2px;
        top: 2px;
        width: 18px;
        height: 18px;
        background-color: $accent;
      }
    }
  }

  .auto-complete {
    position: relative;

    .suggestions {
      height: 0;
      overflow: hidden;
    }
    
    &.display {
      input {
        margin-bottom: 0;
        border-radius: 28px 28px 0 0;
      }

      .suggestions {
        position: absolute;
        width: 100%;
        background: $white;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        border-bottom: 1px solid $border;
        border-radius: 0 0 28px 28px;
        height: auto;

        ul {
          padding: 0 24px;
          list-style-type: none;
          margin: 10px 0;

          li {
            margin-bottom: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }

  /* Slim Select styles */
  .ss-main {
    .ss-single-selected,
    .ss-multi-selected {
      height: auto;
      min-height: 0;
      border-radius: 28px;
      transition: border-radius $easing $duration;
    }

    .ss-open-above + .ss-content {
      border-radius: 28px 28px 0 0;
    }

    .ss-open-below + .ss-content {
      border-radius: 0 0 28px 28px;
    }

    .ss-content .ss-list .ss-option {
      margin: 0;

      :empty {
        display: none;
      }

      &.ss-disabled {
        background-color: transparent;

        &:hover {
          background-color: $disabled-hover
        }
      }
    }
  }
}

.login, .forgottenpassword, .resetpassword  {

  form {

    // input {
    //   border: none;
    // }

    // input[type=submit] {
    //   background: transparent;
    //   color: $white;
    //   border: 2px solid $white;
    //   width: auto;

    //   &:hover {
    //     background: $white;
    //     border-color: $white;
    //     color: $accent;
    //   }
    // }

    // .buttons {

    
    //   a {
    //     color: white;
    //     justify-content: space-between;
    //     align-items: flex-end;
    //   }
    // }

    .login-submit {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .password-form {

    input[type=submit] {
      width: 100%;
    }
  }

}

.publicregister, .brandregister, .agencyregister {
  form {
    input[type=submit] {
      margin: 20px auto -22px;
    }
  }
}

.directory-search {
  display: flex;
  margin-bottom: 24px;
  margin-top: -36px;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 25px 32px 24px;
  }

  .button {
    padding: 22px 32px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    svg {
      width: 20px;
      height: 20px;
      margin: 0 8px 0 0 ;
    }
  }
}

.filters {
  display: flex;

  .button {
    padding: 15px 32px;
  }

  .select-wrapper {
    margin-right: 8px;
    flex: 1;

    @media ( min-width: $min-tablet) {
      flex: 2;
    }
  }
}
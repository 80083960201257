//declare fonts here and assign to variables below
//Ensure that you are preloading to the first file in each @font-face rule to improve page speed
//https://nooshu.github.io/blog/2021/01/23/the-importance-of-font-face-source-order-when-used-with-preload/

/* roboto-regular - 400 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap; //Don't forget this!
  src:
  url('/assets/fonts/roboto-v27-latin-regular.woff2') format('woff2'),
  url('/assets/fonts/roboto-v27-latin-regular.woff') format('woff'),
  url('/assets/fonts/roboto-v27-latin-regular.ttf') format('truetype'),
}

/* roboto-regular - 700 */
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap; //Don't forget this!
  src:
  url('/assets/fonts/roboto-v27-latin-700.woff2') format('woff2'),
  url('/assets/fonts/roboto-v27-latin-700.woff') format('woff'),
  url('/assets/fonts/roboto-v27-latin-700.ttf') format('truetype'),
}


$regular: 'Roboto', Helvetica, Arial, sans-serif;
$light: 'Roboto', Helvetica, Arial, sans-serif;
$bold: 'Roboto Bold', Helvetica, Arial, sans-serif;
$italic: 'Roboto', Helvetica, Arial, sans-serif;

//layout

$vertical-space: 80px;
$horizontal-space: 32px;

$vertical-space-mob: 40px;
$horizontal-space-mob: 32px;

$spacing: 32px;  // standard vertical spacing between elements, buttons etc

$base-font-size: 16;

$max-desktop: 1200px;
$min-desktop: 980px;
$min-tablet: 768px;
$small: 480px;
$tiny: 360px;

$max-content-width: 1200px;

///put z-index info here

$block-padding: 32px;


$grid-spacing: 16px; //make this 50% of spacing
$spacing-small: 24px;

//buttons

$button-padding: 16px 32px;

//navigation
$menu-link-padding:                 20px 25px;
$breakpoint:                        950px;
$mega-menu-multiLevel-colWidth:     100/3 + 0%;
$mobile-menu-back-height:           calc(1.4em + 40px);
$mobile-menu-back-offset:           calc(0px - (1.4em + 40px));
$menu-mobile-width:                 350px;
$dropdown-bg: #FFF;
$top-nav-color: #FFF;
$sub-nav-color: #000;
$nav-scroll-bg: #000;


//ANIMATIONS 
$duration: .2s;
$duration-slow: .6s;

$easing: linear;


//colours
$titles: #000000;

$text: #454545;
$text-light: #868686; //#868686;

$accent: #36C593;  //green #36C593;
$accent-hover: #000;
$accent-text: #FFF; // text colour on accent bg
$accent-hover-text: #FFF;
$accent-gradient-to: #CFEAFF; // text colour on accent bg
$border: #DBDBDB;

$disabled-hover: #ECECEC;

//footer vars

$footer-spacing-mob: 40px 0;
$footer-spacing: 100px 0;
$disclaimer-spacing-mob: 20px 0;
$disclaimer-spacing: 30px 0;

$footer: #2A2A2A;
$footer-text: #FFF;
$disclaimer-text: rgba(255, 255, 255, .6);
$disclaimer: #161616;
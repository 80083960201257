@charset "UTF-8";
lite-youtube {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  max-width: 720px;
}

/* gradient */
lite-youtube::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
lite-youtube::after {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}

lite-youtube > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

/* play button */
lite-youtube > .lty-playbtn {
  width: 68px;
  height: 48px;
  position: absolute;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  z-index: 1;
  background-color: transparent;
  /* YT's actual play button svg */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 68 48"><path fill="%23f00" fill-opacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path><path d="M 45,24 27,14 27,34" fill="%23fff"></path></svg>');
  filter: grayscale(100%);
  transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
  border: none;
}

lite-youtube:hover > .lty-playbtn,
lite-youtube .lty-playbtn:focus {
  filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
  cursor: unset;
}

lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.ss-main {
  position: relative;
  display: inline-block;
  user-select: none;
  color: #666666;
  width: 100%;
}
.ss-main .ss-single-selected {
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 30px;
  padding: 6px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #ffffff;
  outline: 0;
  box-sizing: border-box;
  transition: background-color 0.2s;
}
.ss-main .ss-single-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed;
}
.ss-main .ss-single-selected.ss-open-above {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.ss-main .ss-single-selected.ss-open-below {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ss-main .ss-single-selected .placeholder {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  width: calc(100% - 30px);
  line-height: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ss-main .ss-single-selected .placeholder * {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
}
.ss-main .ss-single-selected .placeholder .ss-disabled {
  color: #dedede;
}
.ss-main .ss-single-selected .ss-deselect {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px 0 6px;
  font-weight: bold;
}
.ss-main .ss-single-selected .ss-deselect.ss-hide {
  display: none;
}
.ss-main .ss-single-selected .ss-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px 0 6px;
}
.ss-main .ss-single-selected .ss-arrow span {
  border: solid #666666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.2s, margin 0.2s;
}
.ss-main .ss-single-selected .ss-arrow span.arrow-up {
  transform: rotate(-135deg);
  margin: 3px 0 0 0;
}
.ss-main .ss-single-selected .ss-arrow span.arrow-down {
  transform: rotate(45deg);
  margin: -3px 0 0 0;
}
.ss-main .ss-multi-selected {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-height: 30px;
  width: 100%;
  padding: 0 0 0 3px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #ffffff;
  outline: 0;
  box-sizing: border-box;
  transition: background-color 0.2s;
}
.ss-main .ss-multi-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed;
}
.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-disabled {
  color: #666666;
}
.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-value .ss-value-delete {
  cursor: not-allowed;
}
.ss-main .ss-multi-selected.ss-open-above {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.ss-main .ss-multi-selected.ss-open-below {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ss-main .ss-multi-selected .ss-values {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1 1 100%;
  width: calc(100% - 30px);
}
.ss-main .ss-multi-selected .ss-values .ss-disabled {
  display: flex;
  padding: 4px 5px;
  margin: 2px 0px;
  line-height: 1em;
  align-items: center;
  width: 100%;
  color: #dedede;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scaleOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.ss-main .ss-multi-selected .ss-values .ss-value {
  display: flex;
  user-select: none;
  align-items: center;
  font-size: 12px;
  padding: 3px 5px;
  margin: 3px 5px 3px 0px;
  color: #ffffff;
  background-color: #5897fb;
  border-radius: 4px;
  animation-name: scaleIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}
.ss-main .ss-multi-selected .ss-values .ss-value.ss-out {
  animation-name: scaleOut;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}
.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete {
  margin: 0 0 0 5px;
  cursor: pointer;
}
.ss-main .ss-multi-selected .ss-add {
  display: flex;
  flex: 0 1 3px;
  margin: 9px 12px 0 5px;
}
.ss-main .ss-multi-selected .ss-add .ss-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #666666;
  position: relative;
  height: 10px;
  width: 2px;
  transition: transform 0.2s;
}
.ss-main .ss-multi-selected .ss-add .ss-plus:after {
  background: #666666;
  content: "";
  position: absolute;
  height: 2px;
  width: 10px;
  left: -4px;
  top: 4px;
}
.ss-main .ss-multi-selected .ss-add .ss-plus.ss-cross {
  transform: rotate(45deg);
}

.ss-content {
  position: absolute;
  width: 100%;
  margin: -1px 0 0 0;
  box-sizing: border-box;
  border: solid 1px #dcdee2;
  z-index: 1010;
  background-color: #ffffff;
  transform-origin: center top;
  transition: transform 0.2s, opacity 0.2s;
  opacity: 0;
  transform: scaleY(0);
}
.ss-content.ss-open {
  display: block;
  opacity: 1;
  transform: scaleY(1);
}
.ss-content .ss-search {
  display: flex;
  flex-direction: row;
  padding: 8px 8px 6px 8px;
}
.ss-content .ss-search.ss-hide {
  height: 0px;
  opacity: 0;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.ss-content .ss-search.ss-hide input {
  height: 0px;
  opacity: 0;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.ss-content .ss-search input {
  display: inline-flex;
  font-size: inherit;
  line-height: inherit;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0px;
  height: 30px;
  padding: 6px 8px;
  margin: 0;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #ffffff;
  outline: 0;
  text-align: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: textfield;
}
.ss-content .ss-search input::placeholder {
  color: #8a8a8a;
  vertical-align: middle;
}
.ss-content .ss-search input:focus {
  box-shadow: 0 0 5px #5897fb;
}
.ss-content .ss-search .ss-addable {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  flex: 0 0 30px;
  height: 30px;
  margin: 0 0 0 8px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  box-sizing: border-box;
}
.ss-content .ss-addable {
  padding-top: 0px;
}
.ss-content .ss-list {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}
.ss-content .ss-list .ss-optgroup .ss-optgroup-label {
  padding: 6px 10px 6px 10px;
  font-weight: bold;
}
.ss-content .ss-list .ss-optgroup .ss-option {
  padding: 6px 6px 6px 25px;
}
.ss-content .ss-list .ss-optgroup-label-selectable {
  cursor: pointer;
}
.ss-content .ss-list .ss-optgroup-label-selectable:hover {
  color: #ffffff;
  background-color: #5897fb;
}
.ss-content .ss-list .ss-option {
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  user-select: none;
}
.ss-content .ss-list .ss-option * {
  display: inline-block;
}
.ss-content .ss-list .ss-option:hover, .ss-content .ss-list .ss-option.ss-highlighted {
  color: #ffffff;
  background-color: #5897fb;
}
.ss-content .ss-list .ss-option.ss-disabled {
  cursor: not-allowed;
  color: #dedede;
  background-color: #ffffff;
}
.ss-content .ss-list .ss-option:not(.ss-disabled).ss-option-selected {
  color: #666666;
  background-color: rgba(88, 151, 251, 0.1);
}
.ss-content .ss-list .ss-option.ss-hide {
  display: none;
}
.ss-content .ss-list .ss-option .ss-search-highlight {
  background-color: #fffb8c;
}

/* This file has been ignored from the linting tools to stop errors for duplicate properties. Please write nice code. */
/* convert to em from px */
/* convert to rem from px */
/* unit conversion used by font size */
/* Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/* Slightly darken a color
 * @access public
 * @param {Color} $color - color to shade
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/* roboto-regular - 400 */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/roboto-v27-latin-regular.woff2") format("woff2"), url("/assets/fonts/roboto-v27-latin-regular.woff") format("woff"), url("/assets/fonts/roboto-v27-latin-regular.ttf") format("truetype");
}
/* roboto-regular - 700 */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/roboto-v27-latin-700.woff2") format("woff2"), url("/assets/fonts/roboto-v27-latin-700.woff") format("woff"), url("/assets/fonts/roboto-v27-latin-700.ttf") format("truetype");
}
footer {
  background: #2A2A2A;
  color: #FFF;
}
footer .footer-links {
  display: flex;
  padding: 24px 0;
}
footer .footer-links a {
  color: white;
  margin-right: 36px;
  transition: 0.2s linear;
}
footer .footer-links a:hover {
  color: #36C593;
}
footer .footer-content {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 768px) {
  footer .footer-content {
    flex-direction: row;
  }
}
@media (min-width: 980px) {
  footer .footer-content {
    padding: 100px 0;
  }
}
footer .copyright {
  background: #161616;
  color: rgba(255, 255, 255, 0.6);
}
footer .copyright .copyright-content {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
  padding: 30px 0;
  color: rgba(255, 255, 255, 0.6);
}
@media (min-width: 980px) {
  footer .copyright .copyright-content {
    padding: 30px 0;
  }
}
footer .copyright .copyright-content p {
  margin-bottom: 0;
}
footer .social ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0;
  padding: 0;
}
@media (min-width: 768px) {
  footer .social ul {
    justify-content: space-between;
  }
}
footer .social ul li {
  list-style: none;
  padding: 8px;
}
footer .social ul li:first-child {
  padding-left: 0;
}
footer .social ul li:last-child {
  padding-right: 0;
}
footer .social ul li a {
  transition: 0.2s linear;
  color: #36C593;
}
footer .social ul li a svg {
  width: 20px;
  height: 20px;
}
footer .social ul li a:hover {
  color: white;
}

.imgZoom {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: 0.2s linear;
}
.imgZoom:hover {
  transform: scale(1.1);
}
@media (prefers-reduced-motion) {
  .imgZoom:hover {
    transform: scale(1);
  }
}

.img:hover .imgZoom {
  transform: scale(1.1);
  /* Tone down the animation to avoid vestibular motion triggers like scaling or panning large objects. */
}
@media (prefers-reduced-motion) {
  .img:hover .imgZoom {
    transform: scale(1);
  }
}

.parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.leftIn, .rightIn {
  opacity: 0;
}
@media (prefers-reduced-motion: no-preference) {
  .leftIn, .rightIn {
    transition: 0.6s linear;
  }
}

.rightIn {
  transform: translate(300px);
}

.leftIn {
  transform: translate(-300px);
}

section.is-active .leftIn, section.is-active .rightIn {
  opacity: 1;
  transform: translate(0);
}

/* Tone down the animation to avoid vestibular motion triggers like scaling or panning large objects. */
@media (prefers-reduced-motion) {
  .leftIn {
    margin-left: 0;
  }

  .rightIn {
    margin-right: 0;
  }
}
.delay-one {
  transition-delay: 0.1s;
}

.delay-two {
  transition-delay: 0.2s;
}

.delay-three {
  transition-delay: 0.3s;
}

.delay-four {
  transition-delay: 0.4s;
}

.swipeIn {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.swipeIn .image-reveal {
  background-color: #ffffff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: 0.6s linear;
}
@media (prefers-reduced-motion) {
  .swipeIn .image-reveal {
    width: 0;
  }
}
.swipeIn.is-active .image-reveal {
  width: 0;
}
.swipeIn.right .image-reveal {
  left: auto;
  right: 0;
}

.swipeUp {
  margin: 5px;
  will-change: transform;
}

.number-counter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.number-counter .final-number {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 38px;
  line-height: 2.375rem;
}

.buttons {
  display: flex;
  margin: 20px 0;
}

.full-page .page {
  height: 100vh;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotating-text {
  align-items: center;
  display: flex;
  margin: 40px 0 200px;
  text-align: center;
}
.rotating-text-content {
  position: relative;
  width: 100%;
}
.rotating-text-description {
  font-style: italic;
  font-size: 14px;
  margin: 0;
}
@media (min-width: 768px) {
  .rotating-text-description {
    font-size: 18px;
  }
}
.rotating-text-adjective {
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  left: 0;
  margin-bottom: 0;
  margin-top: 80px;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
}
@media (min-width: 768px) {
  .rotating-text-adjective {
    font-size: 70px;
  }
}
.is-active .rotating-text-adjective:nth-of-type(1) {
  animation: rotate-text-up 1.5s 0.75s;
}
.is-active .rotating-text-adjective:nth-of-type(2) {
  animation: rotate-text-up 1.5s 2s;
}
.is-active .rotating-text-adjective:nth-of-type(3) {
  animation: fade-text-in 1.5s 3.25s forwards;
}

@keyframes rotate-text-up {
  0% {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  }
  20%, 80% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -40px, 0);
    opacity: 0;
  }
}
@keyframes fade-text-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 80px, 0);
  }
  50%, 100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.colour-block-in {
  width: 100%;
  margin: 20px 0;
  padding: 40px 0;
  background: #232323;
  display: flex;
  justify-content: center;
  align-items: center;
}
.colour-block-in .colour-block {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
}
.colour-block-in .colour-block .title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}
.colour-block-in .colour-block .title .block {
  width: 0%;
  height: inherit;
  background: LemonChiffon;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
  z-index: 5;
  top: -15px;
}
.colour-block-in .colour-block .title h1 {
  color: #ffffff;
  font-size: 32px;
  -webkit-animation: mainFadeIn 2s forwards;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1s;
  opacity: 0;
  display: flex;
  position: relative;
}
.colour-block-in .colour-block .subtitle {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: -10px;
}
.colour-block-in .colour-block .subtitle .block {
  width: 0%;
  height: inherit;
  background: LightCoral;
  z-index: 5;
  position: absolute;
  animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  display: flex;
  top: -10px;
}
.colour-block-in .colour-block .subtitle p {
  animation: secFadeIn 2s forwards;
  animation-delay: 3s;
  opacity: 0;
  font-weight: 400;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
.highlight span {
  background-image: linear-gradient(transparent calc(65% - 5px), #5a5a5a 5px);
  background-size: 0;
  background-repeat: no-repeat;
  display: inline;
  transition: 0.5s ease;
}
.highlight:hover span {
  background-size: 100%;
}

.multiline span {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 77%, currentColor 80%, rgba(0, 0, 0, 0) 83%);
  background-size: 0;
  background-repeat: no-repeat;
  display: inline;
  transition: 0.6s ease;
}
.multiline:hover span {
  background-size: 100%;
}

.faqs .accordion {
  background: white;
  cursor: pointer;
  padding: 15px 30px;
  width: 100%;
  transition: 0.2s linear;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #DBDBDB;
  border-radius: 30px;
  margin-top: 24px;
}
.faqs .accordion .plus-icon {
  width: 20px;
  height: 20px;
  position: relative;
}
.faqs .accordion .plus-icon:before, .faqs .accordion .plus-icon:after {
  content: "";
  position: absolute;
  background-color: #36C593;
  transition: transform 0.2s linear;
}
.faqs .accordion .plus-icon:before {
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  margin-left: -2px;
}
.faqs .accordion .plus-icon:after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  margin-top: -2px;
}
.faqs .accordion .arrow-icon {
  height: 20px;
  width: 35px;
  display: block;
  position: relative;
  cursor: pointer;
}
.faqs .accordion .arrow-icon .left-bar,
.faqs .accordion .arrow-icon .right-bar {
  position: absolute;
  background-color: transparent;
  top: 50%;
  width: 20px;
  height: 5px;
  display: block;
  border-radius: 2px;
}
.faqs .accordion .arrow-icon .left-bar:after,
.faqs .accordion .arrow-icon .right-bar:after {
  content: "";
  background-color: #36C593;
  width: 20px;
  height: 5px;
  display: block;
  transition: all 0.2s linear;
  z-index: -1;
}
.faqs .accordion .arrow-icon .left-bar {
  left: 0;
  transform: translateY(-50%) rotate(35deg);
}
.faqs .accordion .arrow-icon .left-bar:after {
  border-radius: 6px 10px 10px 6px;
}
.faqs .accordion .arrow-icon .right-bar {
  left: 15px;
  transform: translateY(-50%) rotate(-35deg);
}
.faqs .accordion .arrow-icon .right-bar:after {
  border-radius: 10px 6px 6px 10px;
}
.faqs .accordion.active, .faqs .accordion:hover {
  background-color: #36C593;
  color: #FFF;
}
.faqs .accordion.active .plus-icon:before, .faqs .accordion.active .plus-icon:after, .faqs .accordion:hover .plus-icon:before, .faqs .accordion:hover .plus-icon:after {
  background-color: #FFF;
}
.faqs .accordion.active .arrow-icon .left-bar:after,
.faqs .accordion.active .arrow-icon .right-bar:after, .faqs .accordion:hover .arrow-icon .left-bar:after,
.faqs .accordion:hover .arrow-icon .right-bar:after {
  background-color: #FFF;
}
.faqs .accordion.active .plus-icon:before {
  transform: rotate(90deg);
}
.faqs .accordion.active .plus-icon:after {
  transform: rotate(180deg);
}
.faqs .accordion.active .arrow-icon .left-bar:after {
  transform-origin: center center;
  transform: rotate(-70deg);
}
.faqs .accordion.active .arrow-icon .right-bar:after {
  transform-origin: center center;
  transform: rotate(70deg);
}
.faqs .panel {
  max-height: 0;
  overflow: hidden;
  transition: 0.2s linear;
}
.faqs .panel.active {
  max-height: 600px;
}
.faqs .panel .wrapper {
  padding: 32px;
}
.faqs .panel p:last-child {
  margin: 0;
}

.listing {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
}
@media (min-width: 768px) {
  .listing {
    margin: 0;
  }
}
.listing .listing-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.listing .listing-item .img {
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.listing .listing-item .img .img-holder {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .listing .listing-item .img .img-holder {
    transition: 0.2s linear;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .listing .listing-item .img:hover .img-holder {
    transform: scale(1.1);
  }
}
.listing .listing-item .content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.listing .listing-item .content .date {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 20px;
  line-height: 1.25rem;
  text-transform: uppercase;
  color: #868686;
  margin-bottom: 10px;
}
.listing .listing-item .content .title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 32px;
  line-height: 2rem;
  margin-bottom: 24px;
}
.listing .listing-item .content .title a {
  text-decoration: none;
  color: inherit;
  transition: 0.2s linear;
}
.listing .listing-item .content .title a:hover {
  color: #36C593;
}
@media (min-width: 768px) {
  .listing .listing-item .content .title {
    font-size: 32px;
    font-size: 2rem;
    line-height: 40px;
    line-height: 2.5rem;
  }
}
.listing .listing-item .content .author {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
}
.listing .listing-item .content .button {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .listing.grid .listing-item {
    max-width: 50%;
    padding-right: 16px;
    margin-bottom: 32px;
  }
  .listing.grid .listing-item:nth-child(even) {
    padding: 0 0 0 16px;
  }
  .listing.grid .listing-item .content {
    padding: 32px 0;
  }
  .listing.grid .listing-item .content .button {
    align-self: start;
  }
  .listing.grid.three .listing-item {
    max-width: 33.333333%;
    padding: 0 16px;
  }
  .listing.grid.three .listing-item:nth-child(3n) {
    padding: 0 0 0 16px;
  }
  .listing.grid.three .listing-item:nth-child(3n+1) {
    padding: 0 16px 0 0;
  }
}
@media (min-width: 980px) {
  .listing.grid.four .listing-item {
    max-width: 25%;
    padding: 0 16px;
  }
  .listing.grid.four .listing-item:nth-child(4n+1) {
    padding: 0 16px 0 0;
  }
  .listing.grid.four .listing-item:nth-child(4n) {
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .listing:not(.grid) .listing-item {
    margin: 32px 0;
  }
  .listing:not(.grid) .listing-item .img {
    width: 50%;
    order: 2;
    height: 100%;
  }
  .listing:not(.grid) .listing-item .content {
    order: 1;
    width: 50%;
  }
  .listing:not(.grid) .listing-item .content .button {
    align-self: start;
  }
}
@media (min-width: 768px) {
  .listing:not(.grid).alternate .listing-item .img {
    width: 48%;
  }
  .listing:not(.grid).alternate .listing-item .content {
    padding: 32px 60px 32px 32px;
    width: 50%;
    margin-right: 2%;
  }
  .listing:not(.grid).alternate .listing-item:nth-child(even) .img {
    order: 1;
  }
  .listing:not(.grid).alternate .listing-item:nth-child(even) .content {
    margin: 0 0 0 2%;
    order: 2;
    padding: 32px 32px 32px 60px;
  }
}

.tabs {
  width: 100%;
}

[role=tablist] {
  margin: 0 0 -0.1em;
  overflow: visible;
}

[role=tab] {
  position: relative;
  margin: 0;
  padding: 15px 32px;
  border: 1px solid #DBDBDB;
  overflow: visible;
}

[role=tab][aria-selected=true] {
  border-radius: 0;
  background: #36C593;
  color: #FFF;
}

[role=tab][aria-selected=true]:not(:focus):not(:hover)::before {
  border-top: 5px solid #36C593;
}

[role=tab][aria-selected=true]::after {
  position: absolute;
  z-index: 3;
  bottom: -1px;
  right: 0;
  left: 0;
  height: 0.3em;
  background: #36C593;
  box-shadow: none;
  content: "";
}

[role=tab]:hover,
[role=tab]:focus,
[role=tab]:active {
  border-radius: 0;
}

[role=tab]:hover::before,
[role=tab]:focus::before {
  border-color: #36C593;
}

[role=tabpanel] {
  position: relative;
  z-index: 2;
  padding: 32px;
  border: 1px solid #DBDBDB;
}

[role=tabpanel]:focus {
  outline: 0;
}

[role=tabpanel]:focus::after {
  position: absolute;
  bottom: 0;
  right: -1px;
  left: -1px;
  content: "";
}

[role=tabpanel] p {
  margin: 0;
}

[role=tabpanel] * + p {
  margin-top: 1em;
}

.results .result {
  padding: 32px 0;
}
.results .result .title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 32px;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 24px;
}

.pagination {
  margin: 32px 0;
  display: flex;
  justify-content: center;
}
.pagination .pag-button {
  background: #36C593;
  color: #FFF;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #36C593;
  transition: 0.2s linear;
}
.pagination .pag-button:hover {
  background: #FFF;
  color: #36C593;
  text-decoration: none;
}
.pagination .pag-numbers {
  display: flex;
  align-items: center;
}
.pagination .pag-numbers span {
  margin: 0 5px;
  font-weight: 700;
}
.pagination .pag-numbers .pag-number {
  border-radius: 50%;
  border: 1px solid #DBDBDB;
  width: 55px;
  height: 55px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #454545;
  transition: 0.2s linear;
}
.pagination .pag-numbers .pag-number:hover, .pagination .pag-numbers .pag-number.current {
  border: 1px solid #36C593;
  text-decoration: none;
}
.pagination .pag-numbers .pag-number.current {
  color: #36C593;
}

.filter-form {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .filter-form {
    margin-bottom: 80px;
  }
}

.filters {
  display: flex;
  flex-flow: wrap;
}
.filters .filter {
  flex: 0 0 100%;
  margin-bottom: 16px;
}
@media (min-width: 480px) {
  .filters .filter {
    flex: 0 0 50%;
    padding-right: 16px;
  }
}
@media (min-width: 768px) {
  .filters .filter {
    flex: 1 0 auto;
  }
}
@media (min-width: 768px) {
  .filters .filter:last-child {
    padding-right: 0;
  }
}

.form-intro {
  text-align: center;
  margin-bottom: 80px;
}

form {
  width: 100%;
  /*.radioGroup {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div {
      display: flex;
      padding-right: 16px;
      width: 100%;

      @media (min-width: $min-tablet) {
        width: 20%;
      }


    }
  }*/
  /* Slim Select styles */
}
form fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
form fieldset div {
  margin-bottom: 16px;
}
form fieldset div span.small {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 20px;
  line-height: 1.25rem;
  color: #454545;
  margin: 24px 0;
}
form label {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 20px;
  line-height: 1.25rem;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
  width: 100%;
}
form label.radio-container {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
  font-weight: 400;
  margin: 8px 0;
  color: #454545;
  position: relative;
  padding-left: 32px;
}
form label.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
form label.radio-container input:checked ~ .radiobtn {
  background-color: white;
}
form label.radio-container input:checked ~ .radiobtn:after {
  display: block;
}
form label.radio-container .radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #DBDBDB;
  border-radius: 50%;
}
form label.radio-container .radiobtn:after {
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #36C593;
  content: "";
  position: absolute;
  display: none;
}
form .field-validation-error {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
  color: red;
  display: block;
  width: 100%;
}
form .badge-error {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 26px;
  line-height: 1.625rem;
  color: red;
  display: block;
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
}
form input {
  font-family: roboto;
  background: white;
  border-radius: 28px;
  margin-bottom: 8px;
  color: #454545;
  border: 1px solid #DBDBDB;
  padding: 16px 24px;
  width: 100%;
}
form input:focus {
  border-color: #454545;
  outline: 0;
}
form textarea {
  background: white;
  border-radius: 28px;
  margin-bottom: 8px;
  color: #454545;
  border: 1px solid #DBDBDB;
  padding: 16px 24px;
  width: 100%;
  outline: 0;
}
form textarea:focus-within {
  border-color: #454545;
}
form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #868686;
}
form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #868686;
}
form :-ms-input-placeholder {
  /* IE 10+ */
  color: #868686;
}
form :-moz-placeholder {
  /* Firefox 18- */
  color: #868686;
}
form .buttons {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  form .buttons {
    flex-direction: row;
    justify-content: space-between;
  }
}
form input[type=submit] {
  background: #36C593;
  color: #FFF;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  border: 2px solid #36C593;
  transition: 0.2s linear;
  text-decoration: none;
  align-self: flex-start;
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  white-space: nowrap;
  width: auto;
}
form input[type=submit]:hover {
  background: #000;
  color: #FFF;
  border-color: #000;
  text-decoration: none;
}
form input[type=submit]:focus-within {
  outline: 2px solid black;
}
form .radiobuttonlist, form .dataconsent {
  display: flex;
  flex-wrap: wrap;
}
form .radiobuttonlist label, form .dataconsent label {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  width: 100%;
}
form .radiobuttonlist label input, form .dataconsent label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
form .radiobuttonlist label input:checked ~ .checkmark:after, form .dataconsent label input:checked ~ .checkmark:after {
  display: block;
}
form .radiobuttonlist label input:focus-within ~ .checkmark, form .dataconsent label input:focus-within ~ .checkmark {
  outline: 1px solid black;
}
form .radiobuttonlist label .checkmark, form .dataconsent label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #DBDBDB;
}
form .radiobuttonlist label .checkmark:after, form .dataconsent label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 3px;
  top: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #36C593;
}
form .checkboxlist {
  flex-direction: column;
}
form .checkbox,
form .checkboxlist {
  /*margin-top: 10px;*/
  display: flex;
  margin-top: 0;
}
form .checkbox label,
form .checkboxlist label {
  user-select: none;
  display: flex;
  position: relative;
  padding-left: 32px;
  order: 2;
  color: #454545;
  align-items: center;
}
form .checkbox input,
form .checkboxlist input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  order: 1;
  margin-top: 1px;
}
form .checkbox input:checked ~ .checkmark:after,
form .checkboxlist input:checked ~ .checkmark:after {
  display: block;
}
form .checkbox input:focus-within ~ .checkmark,
form .checkboxlist input:focus-within ~ .checkmark {
  outline: 1px solid black;
}
form .checkbox .checkmark,
form .checkboxlist .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #FFF;
  border: 1px solid #DBDBDB;
  margin-top: 3px;
}
form .checkbox .checkmark:after,
form .checkboxlist .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 2px;
  top: 2px;
  width: 18px;
  height: 18px;
  background-color: #36C593;
}
form .auto-complete {
  position: relative;
}
form .auto-complete .suggestions {
  height: 0;
  overflow: hidden;
}
form .auto-complete.display input {
  margin-bottom: 0;
  border-radius: 28px 28px 0 0;
}
form .auto-complete.display .suggestions {
  position: absolute;
  width: 100%;
  background: #ffffff;
  border-left: 1px solid #DBDBDB;
  border-right: 1px solid #DBDBDB;
  border-bottom: 1px solid #DBDBDB;
  border-radius: 0 0 28px 28px;
  height: auto;
}
form .auto-complete.display .suggestions ul {
  padding: 0 24px;
  list-style-type: none;
  margin: 10px 0;
}
form .auto-complete.display .suggestions ul li {
  margin-bottom: 5px;
  cursor: pointer;
}
form .ss-main .ss-single-selected,
form .ss-main .ss-multi-selected {
  height: auto;
  min-height: 0;
  border-radius: 28px;
  transition: border-radius linear 0.2s;
}
form .ss-main .ss-open-above + .ss-content {
  border-radius: 28px 28px 0 0;
}
form .ss-main .ss-open-below + .ss-content {
  border-radius: 0 0 28px 28px;
}
form .ss-main .ss-content .ss-list .ss-option {
  margin: 0;
}
form .ss-main .ss-content .ss-list .ss-option :empty {
  display: none;
}
form .ss-main .ss-content .ss-list .ss-option.ss-disabled {
  background-color: transparent;
}
form .ss-main .ss-content .ss-list .ss-option.ss-disabled:hover {
  background-color: #ECECEC;
}

.login form .login-submit, .forgottenpassword form .login-submit, .resetpassword form .login-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login .password-form input[type=submit], .forgottenpassword .password-form input[type=submit], .resetpassword .password-form input[type=submit] {
  width: 100%;
}

.publicregister form input[type=submit], .brandregister form input[type=submit], .agencyregister form input[type=submit] {
  margin: 20px auto -22px;
}

.directory-search {
  display: flex;
  margin-bottom: 24px;
  margin-top: -36px;
}
.directory-search input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 25px 32px 24px;
}
.directory-search .button {
  padding: 22px 32px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.directory-search .button svg {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}

.filters {
  display: flex;
}
.filters .button {
  padding: 15px 32px;
}
.filters .select-wrapper {
  margin-right: 8px;
  flex: 1;
}
@media (min-width: 768px) {
  .filters .select-wrapper {
    flex: 2;
  }
}

.gallery .listing {
  margin: 0;
}
.gallery .listing .listing-item {
  position: relative;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .gallery .listing .listing-item {
    margin-bottom: 32px;
  }
}
.gallery .listing .listing-item a {
  overflow: hidden;
  position: relative;
}
.gallery .listing .listing-item a .img-holder {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.gallery .listing .listing-item .play {
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  transition: 0.2s linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
}
.gallery .listing .listing-item .play svg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}
.gallery .listing .listing-item:hover .play {
  color: #36C593;
  border-color: #36C593;
}

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
  overflow: hidden;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  transition: transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  /*opacity: 1;*/
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom,
.glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left,
.glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe,
.gslide video {
  outline: none !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-top .gslide-image img,
.desc-bottom .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img,
.desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  /* max-width: 160vmin; */
  margin: auto;
}

.gslide-video::before {
  content: "";
  /*display: block;*/
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #FFF;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: grabbing !important;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #FFF;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-top .gslide-media,
.desc-bottom .gslide-media {
  margin: 0 auto;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left,
.gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom,
.gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/
.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 15px;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #FFF;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #A1A1A1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #FFF;
  font-weight: bold;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc string {
  color: #FFF;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #FFF;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #FFF;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gprev,
.gnext,
.gclose {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gprev svg,
.gnext svg,
.gclose svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gprev.disabled,
.gnext.disabled,
.gclose.disabled {
  opacity: 0.1;
}

.gprev .garrow,
.gnext .garrow,
.gclose .garrow {
  stroke: #FFF;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gnext,
.glightbox-closing .gprev,
.glightbox-closing .gclose {
  opacity: 0 !important;
}

/*Skin */
.glightbox-clean .gslide-description {
  background: #FFF;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: normal;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
  fill: #FFF;
}

.glightbox-clean button:focus:not(.focused):not(.disabled) {
  outline: none;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

/*CSS Animations*/
.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .glightbox-container .ginner-container.desc-top .gslide-image,
.glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }

  .gslide-image img.zoomable {
    cursor: zoom-in;
  }

  .zoomed .gslide-image img.zoomable {
    cursor: grab;
  }

  .gslide-inline {
    max-height: 95vh;
  }

  .gslide-external {
    max-height: 100vh;
  }

  .gslide-description.description-left,
.gslide-description.description-right {
    max-width: 275px;
  }

  .glightbox-open {
    height: auto;
  }

  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }

  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
  }

  .glightbox-clean .description-left .gdesc-inner,
.glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }

  .glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .glightbox-clean .gprev:hover,
.glightbox-clean .gnext:hover,
.glightbox-clean .gclose:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .glightbox-clean .gprev {
    top: 45%;
  }

  .glightbox-clean .gnext {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 300px;
  position: relative;
}
.tns-slider > .tns-item img, .tns-slider > .tns-item iframe {
  height: 100%;
  width: auto;
  max-height: 300px;
}
.tns-slider > .tns-item .play {
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  transition: 0.2s linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
}
.tns-slider > .tns-item .play svg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}
.tns-slider > .tns-item:hover .play {
  color: #36C593;
  border-color: #36C593;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.fade-enter-active, .fade-leave-active {
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.ss-main, input {
  color: #666;
}

.ss-main {
  position: relative;
  display: inline-block;
  user-select: none;
  width: 100%;
}

.ss-main .ss-single-selected {
  display: flex;
  cursor: pointer;
  width: 100%;
  padding: 16px 24px;
  border: 1px solid #dcdee2;
  border-radius: 48px;
  background-color: #FFF;
  box-sizing: border-box;
  transition: background-color 0.2s;
  min-height: 55px;
  margin-bottom: 0;
}

.ss-main .ss-single-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed;
}

.ss-main .ss-single-selected .placeholder {
  flex: 1 1 100%;
  text-align: left;
  width: calc(100% - 30px);
  color: #868686;
  user-select: none;
}

.ss-main .ss-single-selected .placeholder, .ss-main .ss-single-selected .placeholder * {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ss-main .ss-single-selected .placeholder * {
  width: auto;
}

.ss-main .ss-single-selected .placeholder .ss-disabled {
  color: #868686;
}

.ss-main .ss-single-selected .ss-deselect {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px;
  font-weight: 700;
}

.ss-main .ss-single-selected .ss-deselect.ss-hide {
  display: none;
}

.ss-main .ss-single-selected .ss-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px;
}

.ss-main .ss-single-selected .ss-arrow span {
  border: solid #666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.2s, margin 0.2s, -webkit-transform 0.2s;
}

.ss-main .ss-single-selected .ss-arrow span.arrow-up {
  transform: rotate(-135deg);
  margin: 3px 0 0;
}

.ss-main .ss-single-selected .ss-arrow span.arrow-down {
  transform: rotate(45deg);
  margin: -3px 0 0;
}

.ss-main .ss-multi-selected {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  padding: 16px 24px;
  border: 1px solid #dcdee2;
  border-radius: 48px;
  background-color: #FFF;
  box-sizing: border-box;
  transition: background-color 0.2s;
}

.ss-main .ss-multi-selected.ss-disabled {
  background-color: #DCDEE2;
  cursor: not-allowed;
}

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-disabled {
  color: #666;
}

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-value .ss-value-delete {
  cursor: not-allowed;
}

.ss-main .ss-multi-selected.ss-open-above {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ss-main .ss-multi-selected .ss-values {
  display: flex;
  margin-bottom: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1 1 100%;
  width: calc(100% - 30px);
}

.ss-main .ss-multi-selected .ss-values .ss-disabled {
  display: flex;
  align-items: center;
  width: 100%;
  color: #868686;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scaleIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes scaleOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes scaleOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
.ss-main .ss-multi-selected .ss-values .ss-value {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  display: flex;
  user-select: none;
  align-items: center;
  padding: 4px 8px;
  margin: 0 4px 0 0;
  color: #FFF;
  background-color: #36C593;
  border-radius: 26px;
  animation-name: scaleIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.ss-main .ss-multi-selected .ss-values .ss-value.ss-out {
  animation-name: scaleOut;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}

.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete {
  margin: 0 0 0 5px;
  cursor: pointer;
}

.ss-main .ss-multi-selected .ss-add {
  display: flex;
  flex: 0 1 3px;
  margin: 9px 12px 0 5px;
}

.ss-main .ss-multi-selected .ss-add .ss-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #666;
  position: relative;
  height: 10px;
  width: 2px;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.ss-main .ss-multi-selected .ss-add .ss-plus:after {
  background: #666;
  content: "";
  position: absolute;
  height: 2px;
  width: 10px;
  left: -4px;
  top: 4px;
}

.ss-main .ss-multi-selected .ss-add .ss-plus.ss-cross {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ss-content {
  position: absolute;
  width: 100%;
  margin: -1px 0 0;
  box-sizing: border-box;
  border-radius: 16px;
  z-index: 1010;
  background-color: #FFF;
  transform-origin: center top;
  transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s;
  opacity: 0;
  transform: scaleY(0);
}

.ss-content.ss-open {
  display: block;
  opacity: 1;
  transform: scaleY(1);
}

.ss-content .ss-search {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.ss-content .ss-search.ss-hide, .ss-content .ss-search.ss-hide input {
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.ss-content .ss-search input {
  display: inline-flex;
  font-size: inherit;
  line-height: inherit;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  height: 30px;
  padding: 8px;
  margin: 0;
  border: 1px solid #dcdee2;
  border-radius: 48px;
  background-color: #FFF;
  text-align: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: textfield;
}

.ss-content .ss-search input::-webkit-input-placeholder {
  color: #868686;
  vertical-align: middle;
}

.ss-content .ss-search input::-moz-placeholder {
  color: #868686;
  vertical-align: middle;
}

.ss-content .ss-search input:-ms-input-placeholder {
  color: #868686;
  vertical-align: middle;
}

.ss-content .ss-search input::-ms-input-placeholder {
  color: #868686;
  vertical-align: middle;
}

.ss-content .ss-search input::placeholder {
  color: #868686;
  vertical-align: middle;
}

.ss-content .ss-search input:focus {
  border-color: #454545;
}

.ss-content .ss-search .ss-addable {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  height: 30px;
  margin: 0 0 0 8px;
  border: 1px solid #dcdee2;
  border-radius: 48px;
  box-sizing: border-box;
}

.ss-content .ss-addable {
  padding-top: 0;
}

.ss-content .ss-list {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}

.ss-list::-webkit-scrollbar {
  width: 5px;
}

.ss-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #FFF;
  border-radius: 10px;
}

.ss-list::-webkit-scrollbar-thumb {
  border-radius: 48px;
  -webkit-box-shadow: inset 0 0 30px #36C593;
}

​ .ss-content .ss-list .ss-optgroup .ss-optgroup-label {
  padding: 8px 12px;
  font-weight: 700;
}

.ss-content .ss-list .ss-optgroup .ss-option {
  padding: 6px 6px 6px 25px;
}

.ss-content .ss-list .ss-optgroup-label-selectable {
  cursor: pointer;
}

.ss-content .ss-list .ss-optgroup-label-selectable:hover {
  color: #FFF;
  background-color: #5897FB;
}

.ss-content .ss-list .ss-option {
  padding: 8px 24px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s linear;
  color: #454545;
}

.ss-content .ss-list .ss-option * {
  display: inline-block;
}

.ss-content .ss-list .ss-option.ss-highlighted, .ss-content .ss-list .ss-option:hover {
  color: #FFF;
  background-color: #36C593;
}

.ss-content .ss-list .ss-option.ss-disabled {
  cursor: not-allowed;
  color: #868686;
}

.ss-content .ss-list .ss-option:not(.ss-disabled).ss-option-selected {
  color: #454545;
  background-color: rgba(88, 151, 251, 0.1);
}

.ss-content .ss-list .ss-option.ss-hide {
  display: none;
}

.ss-content .ss-list .ss-option .ss-search-highlight {
  background-color: #36C593;
}

.pod-cta {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .pod-cta {
    flex-direction: row;
  }
}
.pod-cta .content {
  position: relative;
  display: flex;
  flex-direction: column;
  order: 2;
  padding: 40px 32px;
}
.pod-cta .img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 375px;
  height: 158px;
  order: 1;
}
.pod-cta .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .pod-cta {
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }
  .pod-cta .content {
    align-items: flex-start;
    order: 1;
    max-width: 450px;
    margin-left: 64px;
  }
  .pod-cta .img {
    order: 2;
    align-self: flex-start;
    height: 375px;
    width: 45%;
    margin-bottom: 64px;
  }
}

.image-with-caption {
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.image-with-caption .img-caption-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 200px;
}
@media (min-width: 768px) {
  .image-with-caption .img-caption-img {
    height: 268px;
  }
}
@media (min-width: 980px) {
  .image-with-caption .img-caption-img {
    height: 440px;
  }
}
.image-with-caption .img-caption-img,
.image-with-caption .video-inner {
  margin-bottom: 15px;
}
.image-with-caption iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
.image-with-caption .video-inner {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.image-with-caption p.caption {
  width: 100%;
  font-style: italic;
  margin-bottom: 0;
}

.image-text {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .image-text {
    flex-direction: row;
  }
}
.image-text .content {
  position: relative;
  display: flex;
  flex-direction: column;
  order: 2;
  max-width: 100%;
  align-items: flex-start;
  padding: 40px 32px;
}
@media (min-width: 768px) {
  .image-text .content {
    max-width: 450px;
  }
}
.image-text .img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  order: 1;
}
.image-text .img img {
  width: 100%;
}
@media (min-width: 768px) {
  .image-text {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .image-text .content {
    width: 50%;
  }
  .image-text .img {
    align-self: flex-start;
    width: 45%;
  }
  .image-text.img-right .content {
    order: 1;
  }
  .image-text.img-right .img {
    order: 2;
  }
}

.content-listing-container {
  display: flex;
  flex-direction: column;
}
.content-listing-container h2 {
  text-align: center;
}
.content-listing-container .content-listing-wrapper {
  display: flex;
  flex-direction: column;
}
.content-listing-container .content-listing-wrapper .content-listing {
  position: relative;
  margin-bottom: 30px;
  height: 243px;
}
.content-listing-container .content-listing-wrapper .content-listing .img {
  height: 100%;
  overflow: hidden;
  display: block;
}
.content-listing-container .content-listing-wrapper .content-listing .img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.content-listing-container .content-listing-wrapper .content-listing .content-listing-image {
  height: 100%;
  z-index: 0;
}
.content-listing-container .content-listing-wrapper .content-listing .content-listing-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}
.content-listing-container .content-listing-wrapper .content-listing .content-listing-text a {
  color: white;
}

@media (min-width: 768px) {
  .content-listing-container .content-listing-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .content-listing-container .content-listing-wrapper .content-listing {
    /*flex: 1;*/
    margin-left: 12px;
    height: 382px;
    margin-bottom: 24px;
    width: calc(50% - 12px);
  }
  .content-listing-container .content-listing-wrapper .content-listing:nth-child(odd) {
    margin-left: 0;
    margin-right: 12px;
  }
}
.map-text {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .map-text {
    flex-direction: row;
  }
}
.map-text .content {
  position: relative;
  display: flex;
  flex-direction: column;
  order: 2;
  width: 100%;
  align-items: flex-start;
  padding: 40px 32px;
}
.map-text .lazyGoogleMap {
  width: 100%;
  height: 70vh;
}
@media (min-width: 768px) {
  .map-text .lazyGoogleMap {
    width: 45%;
    height: 50vh;
  }
}
@media (min-width: 768px) {
  .map-text {
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }
  .map-text .content {
    width: 50%;
  }
}

.news-article {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .news-article {
    margin-top: 80px;
  }
}

.news-article-image {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .news-info-container {
    display: flex;
  }
  .news-info-container > div {
    flex: 1;
  }
}
.news-info-container .news-article-author {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .news-info-container .news-article-author {
    flex-direction: row;
  }
}
.news-info-container .news-article-author img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.news-info-container .news-article-author .auhor-details {
  margin-top: 16px;
  text-align: center;
}
@media (min-width: 768px) {
  .news-info-container .news-article-author .auhor-details {
    margin-left: 16px;
    text-align: left;
    margin-top: 0;
  }
}
.news-info-container .news-article-author .auhor-details span {
  display: block;
}
.news-info-container .news-article-author .auhor-details p {
  margin-bottom: 0.5rem;
}
.news-info-container .news-article-author .auhor-details .author-name {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 26px;
  line-height: 1.625rem;
  font-weight: 600;
}
.news-info-container .news-tags-cat {
  text-align: center;
}
@media (min-width: 768px) {
  .news-info-container .news-tags-cat {
    text-align: right;
  }
}
.news-info-container .news-tags-cat span {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
}
.news-info-container .news-tags-cat .news-article-categories {
  margin-bottom: 24px;
}
.news-info-container .news-tags-cat .news-article-tags {
  margin-bottom: 24px;
}

.news-article-content {
  margin-bottom: 24px;
}

.article-date {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 22px;
  line-height: 1.375rem;
  margin: 0.5rem 0;
}

.news-article-featured {
  margin: 40px auto;
}
@media (min-width: 768px) {
  .news-article-featured {
    margin: 80px auto;
  }
}

.events-article .events-title {
  text-align: center;
  margin-top: 2rem;
}
.events-article .events-date {
  font-size: 48px;
  font-size: 3rem;
  line-height: 56px;
  line-height: 3.5rem;
  text-align: center;
  padding: 2rem;
  margin: 2rem 0;
}
@media (min-width: 768px) {
  .events-article .events-date {
    font-size: 64px;
    font-size: 4rem;
    line-height: 72px;
    line-height: 4.5rem;
  }
}
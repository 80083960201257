//Zoom in images on hover
.imgZoom {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: $duration $easing;

  &:hover {
    transform: scale(1.1);

    @media (prefers-reduced-motion) {
      transform: scale(1);
    }

  }
}

.img {

  &:hover {
    .imgZoom {
      transform: scale(1.1);

      /* Tone down the animation to avoid vestibular motion triggers like scaling or panning large objects. */
      @media (prefers-reduced-motion) {
        transform: scale(1);
      }
    }
  }

}

.parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

// Fly in from left/right
.leftIn, .rightIn {
  opacity: 0;
  @media (prefers-reduced-motion: no-preference) {
    transition: $duration-slow $easing;
  }
}

.rightIn {
  transform: translate(300px);
}

.leftIn {
  transform: translate(-300px);
}

section.is-active {
  .leftIn, .rightIn {
    opacity: 1; 
    transform: translate(0);   
  }
}


/* Tone down the animation to avoid vestibular motion triggers like scaling or panning large objects. */
@media (prefers-reduced-motion) {
  .leftIn {
    margin-left: 0;  
  }
  .rightIn {
    margin-right: 0;  
  }
}


.delay-one {
  transition-delay: .1s; 
}

.delay-two {
  transition-delay: .2s; 
}

.delay-three {
  transition-delay: .3s; 
}

.delay-four {
  transition-delay: .4s; 
}


// Swipe image in with white overlay (color can be changed to match bg)
.swipeIn {
  position: relative;
  overflow: hidden;
  height: 100%;

  .image-reveal {
    background-color: $white;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: $duration-slow $easing;
    @media (prefers-reduced-motion) {
      width: 0;
    }
  }

  &.is-active {
    .image-reveal {
      width: 0;
    }
  }

  &.right {
    .image-reveal {
      left: auto;
      right: 0;
    }
  }
}

// Text will swipe up staggered
.swipeUp {
  margin: 5px;
  will-change: transform;
}


// Number Counter
.number-counter {
  display: flex;
  align-items: center;
  justify-content: center;

  .final-number {
    @include font-size(30);
  }
}

.buttons {
  display: flex;
  margin: 20px 0;
}

// Full page slow scroll 
.full-page {
  // margin-top: 400px;
  .page {
    height: 100vh;
  }
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Rotating three 'p' classes - more can be added but keyframes will change
.rotating-text { 
  align-items: center;
  display: flex;
  margin: 40px 0 200px;
  text-align: center; 

  &-content { 
    position: relative;
    width: 100%;
  }

  &-description { 
    font-style: italic;
    font-size: 14px;
    margin: 0;
    
    @media (min-width: 768px) { 
      font-size: 18px;
    }
  }

  &-adjective { 
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    left: 0;
    margin-bottom: 0;
    margin-top: 80px;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    
    @media (min-width: 768px) { 
      font-size: 70px;
    }

    .is-active & {
      &:nth-of-type(1) { 
        animation: rotate-text-up 1.5s .75s; 
      }
      
      &:nth-of-type(2) { 
        animation: rotate-text-up 1.5s 2s; 
      }  
      
      &:nth-of-type(3) { 
        animation: fade-text-in 1.5s 3.25s forwards;
      }
    }
  }
}

// Rotating text Animations
@keyframes rotate-text-up { 
  0% {
    transform: translate3d(0, 80px, 0);
    opacity: 0;
  }
  
  20%, 80% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  
  100% {
    transform: translate3d(0, -40px, 0);
    opacity: 0;
  }
}

@keyframes fade-text-in { 
  0% {
    opacity: 0;
    transform: translate3d(0, 80px, 0);
  }
  
  50%, 100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.colour-block-in {
  width: 100%;
  margin: 20px 0;
  padding: 40px 0;
  background: #232323;
  display: flex;
  justify-content: center;
  align-items: center;

  .colour-block {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 400px;

    .title {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 50px;

      .block {
        width: 0%;
        height: inherit;
        background: LemonChiffon;
        position: absolute;
        animation: mainBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
        display: flex;
        z-index: 5;
        top: -15px;
      }

      h1 {
        color: $white;
        font-size: 32px;
        -webkit-animation: mainFadeIn 2s forwards;
        animation: mainFadeIn 2s forwards;
        animation-delay: 1s;
        opacity: 0;
        display: flex;
        position: relative;
      }
    }

    .subtitle {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 30px;
      margin-top: -10px;

      .block {
        width: 0%;
        height: inherit;
        background: LightCoral;
        z-index: 5;
        position: absolute;
        animation: secBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
        animation-delay: 2s;
        display: flex;
        top: -10px
      }

      p {
        animation: secFadeIn 2s forwards;
        animation-delay: 3s;
        opacity: 0;
        font-weight: 400;
        color: $white;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 5px;
      }
    }
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;

  }
  50% {
    width: 100%;
    left: 0;

  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;

  }
  50% {
    width: 100%;
    left: 0;

  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


@keyframes secFadeIn {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}


.highlight {
       span {
          background-image: linear-gradient(
            transparent calc(65% - 5px),
            darken(grey, 15%) 5px, 
          );
          background-size: 0;
          background-repeat: no-repeat;
          display: inline;
          transition: 0.5s ease;
        }
        
        &:hover {
          span {
            background-size: 100%;
          }
        }
    }


    .multiline {
       span {
          background-image: linear-gradient(180deg,rgba(0,0,0,0) 77%, currentColor 80%,rgba(0,0,0,0) 83%);
          background-size: 0;
          background-repeat: no-repeat;
          display: inline;
          transition: 0.6s ease;
        }
        
        &:hover {
          span {
            background-size: 100%;
          }
        }
    }


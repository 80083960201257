.pod-cta {
   
  position: relative;
  display: flex;
  flex-direction: column;

  @media(min-width: $min-tablet) {
    flex-direction: row;
  }

  .content {
    position: relative; 
    display: flex;
    flex-direction: column;     
    order: 2;
    padding: $vertical-space-mob $horizontal-space-mob;

  }

  .img {    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 375px;
    height: 158px;     
    order: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
          
  }

  @media(min-width: $min-tablet) {
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: space-between;

    .content {      
      align-items: flex-start;
      order: 1;
      max-width: 450px;
      margin-left: 64px;         
    }

    .img {  
      order: 2;
      align-self: flex-start;
      height: 375px;
      width: 45%;
      margin-bottom: 64px;
    }
  }


  
}